import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideNav from "../components/sidenav"
import Card from "../components/card"

export default function Article({ data }) {
  const post = data.recommendJson
  const sideNavFlag = data.allRecommendJson.edges.length !== 1
  return (
    <Layout>
      <SEO
        title={post.name}
        description={post.description}
        pathname={post.type}
      />
      <div className="flex flex-col-reverse md:flex-row">
        <main
          className={`min-h-screen w-full pt-8 md:pt-32 pb-8 md:static md:max-h-full md:overflow-visible ${sideNavFlag && (`md:w-2/3 lg:w-3/4 xl:w-4/5`)}`}
        >

          <div className="markdown">
            <h1 className="text-2xl sm:text-3xl mb-2">{post.name}</h1>
            <p>{post.description}</p>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-8">
              {post.data.map((item, idx) => (
                item.status === 'publish' && (
                  <Card key={idx} type="recommend" dir={post.type} data={item} />
                )
              )
              )}
            </div>
            <p className="text-right text-xs">
              公開投稿日：{post.date}
              &emsp;最終更新日：{post.last_modified}
            </p>
          </div>
        </main>
        {sideNavFlag && (<SideNav data={data.allRecommendJson.edges} post={post} />)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    recommendJson(type: {eq: $slug}) {
      id
      type
      name
      description
      last_modified(formatString: "YYYY/MM/DD")
      date(formatString: "YYYY/MM/DD")
      fields {
        dir
        slug
      }
      data {
        status
        title
        description
        url
        filetype
        filename
        series {
          title
          url
        }
      }
    }
    allRecommendJson {
      edges {
        node {
          id
          type
          name
          fields {
            dir
            slug
          }
          data {
            title
            description
            url
            filetype
            filename
          }
        }
      }
    }
  }
`