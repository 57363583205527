import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SideNav = (getData) => {
  let dir = getData.data[0].node.fields.dir
  let post = getData.post
  let categoryData = useStaticQuery(graphql`
    query {
      allJsonJson {
        edges {
          node {
            category {
              name
              slug
            }
          }
        }
      }
    }
  `)

  let category = categoryData.allJsonJson.edges[0].node.category.filter(item => item['slug'] === dir)[0]

  function linkClass(idx, slug) {
    let defaultClass = ['block px-3 py-2 text-sm transition duration-200']
    if (idx !== 0) defaultClass.push('border-t border-gray-400 border-dashed')
    if (slug === post.fields.slug) {  //active
      defaultClass.push('text-gray-900')
    } else {  //default
      defaultClass.push('text-gray-600 hover:text-gray-900')
    }
    return defaultClass.join(' ')
  }

  return (
    <aside
      className={`w-full max-h-(screen-16) md:ml-6 md:w-1/3 lg:w-1/4 xl:w-1/5 ${
        getData.position === 'left' && (
          `md:hidden`
        )
      }`}
    >
      <nav
        className="
        pt-4 sm:pt-32 pb-6 sm:pb-8 -mb-0 sticky top-0 h-auto overflow-y-auto border-b-0 border-0"
      >
        <h3 className="text-center border-b-2 border-gray-500 pb-2 mb-1">
          <span className="capitalize text-xl">{category['slug']}</span>
          <span className="block text-xs">{category['name']}</span>
        </h3>
        <div className="side-nav">
          {getData.data.map((item, idx) =>
            <Link
              key={item.node.id}
              to={item.node.fields.slug}
              className={linkClass(idx, item.node.fields.slug)}
            >
              <span
                style={{
                  'display': `inline-block`,
                  'textIndent': `-1.85rem`,
                  'marginLeft': `1.85rem`
                }}
              >
                {dir !== 'recommend' && (
                  `${item.node.fields.filename}　`
                )}
                {dir !== 'recommend'
                  ? (item.node.frontmatter.title)
                  : (item.node.name)
                }
              </span>
            </Link>
          )}
        </div>
        <hr className="border-t-2 border-gray-500" />
      </nav>
    </aside>
  )
}

export default SideNav
