import React from "react"
import { Link } from "gatsby"
import Image from "./image"

function Card(getData) {
  const item = getData.data
  const recommendFlag = 'filename' in item
  return (
    <Link
      to={'fields' in item
        ? (`${item.fields.slug}`)
        : (`${item.url}`)
      }
      className={`card flex flex-col max-w-sm rounded relative overflow-hidden shadow bg-white text-gray-700 mb-4 ${
        recommendFlag ? (
          `card-${getData.type} card-${getData.type}-${getData.dir}`
        ) : (
          `px-4 py-6 relative justify-center text-center`
        )
      } ${
        ('filetype' in item && item.filetype === 'book') && (
          `card-filetype-book`
        )
      }
      `}
      {... recommendFlag &&
        {
          target: `_blank`,
          rel: `noopener noreferrer`
        }
      }
    >
      {recommendFlag && item.filename && (
        <Image filepath={`${getData.type}/${getData.dir}/${item.filename}`} />
      )}
      {(!recommendFlag && item.frontmatter.thumbnail) && (
        <div className="thumbnail"><Image filepath={item.frontmatter.thumbnail} /></div>
      )}
      <div className={`text-center ${
        recommendFlag && (
          `p-4`
        )
      }`}>
        <div className="text-sm" dangerouslySetInnerHTML={{
          __html: 'frontmatter' in item
            ? item.frontmatter.title.replace(/\n/g, '<br />')
            : item.title.replace(/\n/g, '<br />')
        }}/>
        {item.description && (
          <p className="text-gray-600 text-xs" dangerouslySetInnerHTML={{
            __html: item.description.replace(/\n/g, '<br />')
          }}/>
        )}
        {!recommendFlag && (
          <time className="date" dateTime={item.fields.last_modified}>{item.frontmatter.last_modified}</time>
        )}
        {!recommendFlag && (
          <div className={`category category-${item.fields.dir}`}>{item.fields.dir}</div>
        )}
      </div>
    </Link>
  )
}

export default Card
